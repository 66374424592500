html,
body {
  width: 100%;
  height: 100%;
  position: relative;
}

body {
  display: -ms-flexbox;
  display: flex;
  font-family: "cour-font";
  color: #f8fe47;
}

.footer {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  background-color: #000000;
  color: white;
}

#root {
  width: 100%;
}

/* gradient css starts */
body {
  background: linear-gradient(270deg, #f8fe47, #000000);
  background-size: 400% 400%;

  -webkit-animation: AnimationName 14.3s ease infinite;
  -moz-animation: AnimationName 14.3s ease infinite;
  animation: AnimationName 14.3s ease infinite;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* gradient css over */

.container-fluid {
  padding: unset;
}

.nav-link img {
  height: 60px;
}

.nav-link.active {
  border-bottom: 5px solid #f8fe47;
}

@font-face {
  font-family: "cour-font";
  src: url("./assets/fonts/cour.ttf");
}

@font-face {
  font-family: "lein-future-font";
  src: url("./assets/fonts/Lein_Future_by_LeinDesign.ttf");
}

.home-section2 {
  font-family: "lein-future-font";
  font-size: 35px;
  background-color: #000000;
  text-align: center;
  border: 1px solid #f8fe47;
  border-radius: 30px;
  padding: 50px;
}

.home-section2 span {
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #f8fe47;
}

.shop-heading {
  font-family: "lein-future-font";
  color: #000000;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #f8fe47;
}

.home-btn {
  font-family: "lein-future-font";
  font-size: 35px;
  background-color: #000000;
  text-align: center;
  border: 1px solid #f8fe47;
  border-radius: 30px;
  padding: 50px;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #f8fe47;
}

.shop-btn {
  font-family: "lein-future-font";
  font-size: 35px;
  background-color: #000000;
  text-align: center;
  border: 1px solid #f8fe47;
  border-radius: 30px;
  padding: 20px 30px;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #f8fe47;
}

.social-links a {
  color: #f8fe47;
}

.video-section {
  font-family: "lein-future-font";
  font-size: 60px;
  text-align: center;
}

.video-section span {
  color: #000000;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #f8fe47;
}

.btn_submit_default {
  border: 1px solid #111;
  padding: 15px 25px;
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
  color: rgb(248, 254, 71);
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  background-color: transparent;
  cursor: pointer;
}
.btn_submit_default i {
  padding-right: 5px;
  font-size: 12px;
}
.btn_submit_default:hover {
  background-color: #f8fe47;
  border-color: #f8fe47;
  color: #000000;
}

input[type="text"],
input[type="email"],
select.form-control {
  background: transparent;
  border: none;
  border-bottom: 1px solid #000000;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
}

input[type="text"]:focus,
input[type="email"]:focus,
select.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: transparent;
  border-bottom: 1px solid #000000 !important;
}

textarea {
  background: transparent !important;
  border: none !important;
  border-bottom: 1px solid #000000 !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
}

textarea:focus {
  -webkit-box-shadow: none;
  box-shadow: none !important;
  background: transparent;
  border-bottom: 1px solid #000000 !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #000000 !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000000 !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000000 !important;
}

.about-content div::-webkit-scrollbar {
  width: 20px;
  left: 30px;
  background-color: #f8fe47;
  outline: 1px solid deeppink;
}

.about-content div::-webkit-scrollbar-thumb {
  background: #000000;
  border: 4px solid #f8fe47;
}

.about-content {
  border: 1px solid #f8fe47;
  border-radius: 30px;
  padding: 15px 30px 15px 30px;
  height: 500px;
  background-color: #000000;
}
.about-content div {
  overflow-y: scroll;
  height: 460px;
}

.ReactGridGallery_tile-viewport {
  border: 1px solid #f8fe47;
  border-radius: 15px;
}

.ReactGridGallery_tile {
  margin: 30px !important;
  border-radius: 15px;
}
